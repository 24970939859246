import planApi from "@/api/planApi.js";

import userUtil from "../../util/userUtil";
import { HudGlobal } from "../../util/HudGlobal";
import OfflineParam from "../../util/OfflineParam";
import StoreTrainDBManger from "../../db/StoreTrainDBManger";

import HomeGuideSteps from "@/util/guide-step/HomeGuideSteps";
import GuideStepHelper from "@/util/guide-step/GuideStepHelper";
import ConstantsGuideCode from "@/util/constants/ConstantsGuideCode";
import TBDriver from "@tibi/vue-driver/TBDriver.js";
import TrainFormatterHelper from "../../util/formatter/TrainFormatterHelper";
import TrackConstants from "../../util/constants/TrackConstants";
import TibiMatomoManger from "../../util/manager/TibiMatomoManger";
import DateUtil from "../../util/DateUtil";
import { getCurrentUserId, getCurrentUserOrgId, getUserId, getUserOrgId } from "../../util/user/UserManger";
import baseApi from "../../api/base/baseApi";
import { MEETING, TRAIN } from "../../util/constants/RouteConstant";
import { SYSTEM_TYPE } from "../../util/constants/EnumConstants";

export default {
    name: "HomeList",
    data() {
        return {
            planList: [],
            isFristEntry: true, // 是否是第一次进入页面
            // 请求数据
            params: {
                pageNumber: 1,
                // pageSize: Constants.pageSizeDefault,
                // 暂时不分页, 查询所有有效的计划, 10000条数据
                pageSize: 10000,
                orgId: "",
                userId: "",
                planName: "", // 计划名称
                // 现场学习（0：否，1：是）
                studyOffline: 1,
                // 全部则不传, 0未开始, 1进行中, 4已完成
                state: null,
                startTime: "", // 开始时间
                endTime: "", // 结束时间
                subjectCode: "", // 类别
                type: "train", // 培训 用于查询培训类别
            },
            formObjExtra: {
                subjectList: [], // 培训类别列表
                periodTime: [], // 计划周期
            },

            loadingTip: "加载中...",
            // 路由参数
            routerParam: {
                userId: null,
                tempId: null,
            },

            steps: {},
        };
    },
    computed: {},
    filters: {
        ellipsisText(value) {
            if (value.length > 30) {
                const temp = value.substring(0, 29);
                return `${temp}...`;
            }
            return value;
        },
        /**
         *  准备培训的按钮文字
         * @param {*} item
         */
        formatterPrepareTrainButtonText(item) {
            let state = item.state;
            state = parseInt(state);
            // 状态（0:暂停，11：无效，12：草稿，13：审核驳回，14：审核通过，20：未开始(计划开始时间大于发布日期的计划)，21：进行中,22:补学中，25：完成，30：过期未完成)
            const finishStateList = [25, 30];
            if (finishStateList.includes(state)) {
                return "培训记录";
            } else {
                return "准备培训";
            }
        },
    },
    mounted() {},
    watch: {},
    created() {
        this.isFristEntry = true;

        // 全局界面标识 plan prepare sign play
        window.localStorage.setItem("currentStep", "plan");

        this.setupInitParam();
        this.loadSubjectList();
        this.getPlanList();
    },
    methods: {
        /**
         * 初始化参数
         */
        setupInitParam() {
            this.params = {
                pageNumber: 1,
                pageSize: 10000,
                orgId: getCurrentUserOrgId(),
                userId: getCurrentUserId(),

                // 现场学习（0：否，1：是）
                studyOffline: 1,
                // 全部则不传, 0未开始, 1进行中, 4已完成
                state: null,
                planName: "", // 计划名称
                startTime: "", // 开始时间
                endTime: "", // 结束时间
                subjectCode: null, // 类别
                type: "train", // 培训 用于查询培训类别
            };
            this.formObjExtra.periodTime = [];
        },
        /**
         * 重置
         */
        resetQuery() {
            this.setupInitParam();
        },

        /**
         * 监听计划周期
         */
        monitorChangePlanPeriod(val) {
            if (val) {
                this.params.startTime = val[0];
                this.params.endTime = val[1];
            } else {
                this.params.startTime = null;
                this.params.endTime = null;
            }
        },
        /*
         * 是否能开始培训
         * */
        isShowTrainButton(item) {
            return item.state == 14 || item.state == 20 || item.state == 21 || item.state == 22 || item.state == 25 || item.state == 30;
        },
        /*
         * 强制关闭页面
         * */
        logoutToClose() {
            HudGlobal.showUniversalAlert("现场登录失败, 请回到管理端重新进入!", { showClose: false }).then(() => {
                window.close();
            });
        },

        //======================================================网络请求============================================
        /**
         * 获取培训类别
         */
        loadSubjectList() {
            const that = this;
            const param = {
                orgId: getCurrentUserOrgId(),
                orgMode: 1, //查询组织权限(0:否,1:是)
                type: "train", // 培训 用于查询培训类别
            };
            baseApi.getSubjectList(param).then((res) => {
                if (res.success) {
                    that.formObjExtra.subjectList = res.data || [];
                    that.formObjExtra.subjectList.unshift({
                        subjectCode: null,
                        subjectName: "所有",
                    });
                }
            });
        },
        /*
         * 获取计划列表
         * */
        getPlanList() {
            let _this = this;
            planApi.getPage(this.params).then((res) => {
                console.log(res);
                if (res.success) {
                    let dataList = res.data;
                    _this.planList = dataList;

                    // 检测操作引导
                    _this.checkLivelearningOperationGuide();
                } else {
                    _this.planList = [];
                    _this.loadingTip = _this.formatterMessage();
                    const description = _this.formatterMessage();
                    HudGlobal.showMessage(description || "暂无数据");
                }
            });
        },

        /*
         * 准备培训
         * */
        toPreparePlan(item) {
            TBDriver.resetSteps();
            TibiMatomoManger.sendOffLineTrackEvent(
                TrackConstants.eventCategoryNames.home,
                TrackConstants.eventActionNames.home_list,
                TrackConstants.eventNameKeys.home_list_preparation_train
            );
            const planId = item.planId;
            // 初始化培训参数
            const that = this;
            let checkPlanValidFlag = true;
            if (item.state == 25 || item.state == 30) {
                checkPlanValidFlag = false;
            }

            OfflineParam.initPlanParam({ planId, checkPlanValidFlag }, function() {
                that.gotoPreparePlanController(item);
            });
        },
        /**
         * 提交到培训准备页面
         */
        gotoPreparePlanController(item) {
            // 保存选择的计划
            StoreTrainDBManger.saveCurrentPlanItemToDB(item);
            this.$router.push({
                path: TRAIN.TRAIN_PREPARE,
                query: {
                    type: SYSTEM_TYPE.TRAIN,
                    ...item,
                },
            });
        },

        /*
         * 上传培训资料 /uploadMaterials
         * */
        toUploadMaterial(planInfo) {
            this.$router.push({
                path: "/uploadMaterials",
                query: {
                    type: SYSTEM_TYPE.TRAIN,
                    ...planInfo,
                },
            });
        },

        /*
         * 退出
         * */
        logoutDo() {
            HudGlobal.showAlertConfirmMessage("是否要关闭现场学习页面").then(() => {
                userUtil.removeUserData(window.close());
            });
        },

        /**
         * 培训类别
         */
        formatterVueSubjectNameStr(item) {
            return TrainFormatterHelper.formatterManySubjectNameStr(item);
        },
        /**
         * 所属行业
         * @param {*} item
         */
        formatterVueIndustryNameStr(item) {
            return TrainFormatterHelper.formatterManyIndeustryNameStr(item);
        },
        /**
         * 岗位
         * @param {*} item
         */
        formatterVueDutyNameStr(item) {
            return TrainFormatterHelper.formatterVueDutyNameStr(item);
        },
        formatterMessage() {
            let state = this.params.state;
            // 未开始
            let message = "";
            switch (state) {
                case 0:
                    message = "本企业暂无【未开始】的现场学习计划，请先新增计划，后培训!";
                    break;
                case 1:
                    message = "本企业暂无【进行中】的现场学习计划!";
                    break;
                case 4:
                    message = "本企业暂无【已完成】的现场学习计划!";
                    break;
                default:
                    message = "本企业暂无现场学习计划，请先新增计划，后培训!";
                    break;
            }
            return message;
        },
        /**
         * 时间处理
         * */
        timeFilter(time) {
            let newTime = "0秒";
            if (time) {
                newTime = DateUtil.missToMs(time);
            }
            return newTime;
        },
        /** 未使用
         * 总时长
         * */
        totalHoursFilter(item) {
            let newTime = "0秒";
            const totalHours = item.totalHours;
            if (totalHours && totalHours > 0) {
                newTime = totalHours / 60 + "分钟";
            }
            return newTime;
        },
        /**
         * 检测操作引导
         */
        checkLivelearningOperationGuide() {
            if (this.isFristEntry) {
                this.isFristEntry = false;
                GuideStepHelper.loadOperationGuideData(ConstantsGuideCode.homeGuideCodes, function(dataList) {
                    if (dataList == null || dataList.length == 0) {
                        return;
                    }
                    for (let i = 0; i < dataList.length; i++) {
                        const guide = dataList[i];
                        //是否已完成引导(0:未完成,1:已完成)
                        if (guide.guideDone == 0) {
                            const keyCode = guide.guideCode;

                            if (keyCode == "step200") {
                                TBDriver.addDriverStep(
                                    HomeGuideSteps.createLivelearningGuideSteps(
                                        "#step200",
                                        "查看计划",
                                        "点击【准备培训】，进入此计划现场培训准备页",
                                        null,
                                        200,
                                        200,
                                        true,
                                        true,
                                        false,
                                        ConstantsGuideCode.homeGuideCodes
                                    )
                                );
                            }
                        }
                    }
                    TBDriver.startDriver();
                });
            }
        },
    },
};
