var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "container home-list-wrapper" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "search-form",
          model: {
            value: _vm.params,
            callback: function($$v) {
              _vm.params = $$v
            },
            expression: "params"
          }
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "tb-form-item-search",
              attrs: { label: "计划名称" }
            },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入计划名称", clearable: "" },
                model: {
                  value: _vm.params.planName,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.params,
                      "planName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "params.planName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "tb-form-item-search",
              attrs: { label: "计划状态", prop: "state" }
            },
            [
              _c(
                "el-select",
                {
                  attrs: { filterable: "" },
                  model: {
                    value: _vm.params.state,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.params,
                        "state",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "params.state"
                  }
                },
                [
                  _c("el-option", { attrs: { value: null, label: "所有" } }),
                  _c("el-option", { attrs: { value: 0, label: "未开始" } }),
                  _c("el-option", { attrs: { value: 1, label: "进行中" } }),
                  _c("el-option", { attrs: { value: 4, label: "已完成" } })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "tb-form-item-search",
              attrs: { label: "培训类别" }
            },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择培训类别" },
                  model: {
                    value: _vm.params.subjectCode,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "subjectCode", $$v)
                    },
                    expression: "params.subjectCode"
                  }
                },
                _vm._l(_vm.formObjExtra.subjectList, function(item) {
                  return _c("el-option", {
                    key: item.subjectCode,
                    attrs: { label: item.subjectName, value: item.subjectCode }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "tb-form-item-search",
              attrs: { label: "计划周期" }
            },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  "default-time": ["00:00:00", "23:59:59"],
                  format: "yyyy-MM-dd",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期"
                },
                on: { change: _vm.monitorChangePlanPeriod },
                model: {
                  value: _vm.formObjExtra.periodTime,
                  callback: function($$v) {
                    _vm.$set(_vm.formObjExtra, "periodTime", $$v)
                  },
                  expression: "formObjExtra.periodTime"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticClass: "tb-form-item-search" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.getPlanList } },
                [_vm._v("查询")]
              ),
              _c("el-button", { on: { click: _vm.resetQuery } }, [
                _vm._v("重置")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "ul",
        { staticClass: "plan-list-wrap" },
        [
          !_vm.planList || _vm.planList.length === 0
            ? _c("div", [_vm._v(_vm._s(_vm.loadingTip))])
            : _vm._e(),
          _vm._l(_vm.planList, function(item) {
            return _c("li", { key: item.planId }, [
              _c("div", { staticClass: "list-title" }, [
                _c("div", { staticClass: "plan-title" }, [
                  _vm._v(_vm._s(item.planName))
                ]),
                _c("div", { staticClass: "hd-tag hd-state-tag" }, [
                  _vm._v(_vm._s(_vm._f("planState")(item.state)))
                ]),
                _c("div", { staticClass: "hd-tag hd-type-tag" }, [
                  _vm._v(_vm._s(_vm._f("filterPlanType")(item.addType)))
                ]),
                _c("div", { staticClass: "hd-tag hd-subject-tag" }, [
                  _vm._v(
                    _vm._s(_vm.formatterVueSubjectNameStr(item.extJsonObj))
                  )
                ])
              ]),
              _c("ul", { staticClass: "plan-list" }, [
                _c("li", [
                  _vm._m(0, true),
                  _c("div", { staticClass: "row-desc" }, [
                    _vm._v(
                      _vm._s(_vm._f("timeFormatFilter")(item.startTime)) +
                        " 至 " +
                        _vm._s(_vm._f("timeFormatFilter")(item.endTime))
                    )
                  ])
                ]),
                _c("li", [
                  _vm._m(1, true),
                  _c("div", { staticClass: "row-desc" }, [
                    _vm._v(_vm._s(item.userCount) + "人")
                  ])
                ]),
                _c(
                  "li",
                  [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: "要求学员培训的总时长",
                          placement: "top"
                        }
                      },
                      [
                        _c("div", { staticClass: "row-title" }, [
                          _c("span", [_vm._v("培训时长")]),
                          _vm._v("：")
                        ])
                      ]
                    ),
                    _c("div", { staticClass: "row-desc" }, [
                      _vm._v(_vm._s(_vm.timeFilter(item.totalHours)))
                    ])
                  ],
                  1
                ),
                _c("li", [
                  _vm._m(2, true),
                  _c("div", { staticClass: "row-desc" }, [
                    _vm._v(_vm._s(_vm.timeFilter(item.courseDuration)))
                  ])
                ]),
                _c("li", [
                  _vm._m(3, true),
                  _c("div", { staticClass: "row-desc" }, [
                    _vm._v(_vm._s(item.orgName))
                  ])
                ]),
                _c("li", [
                  _vm._m(4, true),
                  _c("div", { staticClass: "row-desc" }, [
                    _vm._v(
                      "已完成" +
                        _vm._s(item.studyFinishCount || 0) +
                        "人，剩余" +
                        _vm._s(item.userCount - item.studyFinishCount) +
                        "人"
                    )
                  ])
                ]),
                _c("li", [
                  _vm._m(5, true),
                  _c("div", { staticClass: "row-desc" }, [
                    _vm._v(_vm._s(item.addType == 11 ? "实时计划" : "补录计划"))
                  ])
                ]),
                _c("li", [
                  _vm._m(6, true),
                  _c("div", { staticClass: "row-desc" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("ellipsisText")(
                          _vm.formatterVueSubjectNameStr(item.extJsonObj)
                        )
                      )
                    )
                  ])
                ]),
                _c(
                  "li",
                  [
                    _vm._m(7, true),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: { effect: "dark", placement: "top-start" }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "tooltip-width",
                            attrs: { slot: "content" },
                            slot: "content"
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.formatterVueIndustryNameStr(item.extJsonObj)
                              )
                            )
                          ]
                        ),
                        _c("div", { staticClass: "row-desc" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("ellipsisText")(
                                _vm.formatterVueIndustryNameStr(item.extJsonObj)
                              )
                            )
                          )
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "li",
                  [
                    _vm._m(8, true),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: { effect: "dark", placement: "top-start" }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "tooltip-width",
                            attrs: { slot: "content" },
                            slot: "content"
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.formatterVueDutyNameStr(item.extJsonObj)
                              )
                            )
                          ]
                        ),
                        _c("div", { staticClass: "row-desc" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("ellipsisText")(
                                _vm.formatterVueDutyNameStr(item.extJsonObj)
                              )
                            )
                          )
                        ])
                      ]
                    )
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { staticClass: "handle-btns" },
                [
                  _vm.isShowTrainButton(item)
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text", id: "step200" },
                          on: {
                            click: function($event) {
                              return _vm.toPreparePlan(item)
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "el-icon-document" }),
                          _vm._v(
                            _vm._s(
                              _vm._f("formatterPrepareTrainButtonText")(item)
                            )
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ])
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "row-title" }, [
      _c("span", [_vm._v("计划周期")]),
      _vm._v("：")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "row-title" }, [
      _c("span", [_vm._v("参加学员")]),
      _vm._v("：")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "row-title" }, [
      _c("span", [_vm._v("课件总时长")]),
      _vm._v("：")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "row-title" }, [
      _c("span", [_vm._v("所属组织")]),
      _vm._v("：")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "row-title" }, [
      _c("span", [_vm._v("培训进度")]),
      _vm._v("：")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "row-title" }, [
      _c("span", [_vm._v("计划类型")]),
      _vm._v("：")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "row-title" }, [
      _c("span", [_vm._v("培训类别")]),
      _vm._v("：")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "row-title" }, [
      _c("span", [_vm._v("所属行业")]),
      _vm._v("：")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "row-title" }, [
      _c("span", [_vm._v("岗位")]),
      _vm._v("：")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }