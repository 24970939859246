import ca from "element-ui/src/locale/lang/ca";

export default {
    /**
     * 多培训类别
     */
    formatterManySubjectNameStr(item) {
        if (item == null || item.subjectList == null || item.subjectList.length == null) {
            return "无";
        }
        if (item instanceof String) {
            return item;
        }
        const temp = [];
        for (let i = 0; i < item.subjectList.length; i++) {
            const object = item.subjectList[i];
            const strObject = this.formatterSignalSubjectItemStr(object);
            temp.push(strObject);
        }
        const subjectStr = temp.join(",");
        return subjectStr;
    },
    /**
     * 单个类别
     */
    formatterSignalSubjectItemStr(item) {
        let str = "";
        if (item.industryName != null && item.industryName.length != 0) {
            str = str + item.industryName;
        }
        if (item.projectName != null && item.projectName.length != 0) {
            if (item.industryName != null && item.industryName.length != 0) {
                str = str + "-";
            }
            str = str + item.projectName;
        }
        if (item.subjectName != null && item.subjectName.length != 0) {
            if (item.projectName != null && item.projectName.length != 0) {
                str = str + "-";
            }
            str = str + item.subjectName;
        }
        if (item.dutyName != null && item.dutyName.length != 0) {
            if (item.subjectName != null && item.subjectName.length != 0) {
                str = str + "-";
            }
            str = str + item.dutyName;
        }
        return str;
    },
    /**
     * 所属行业
     * @param {*} item
     */
    formatterManyIndeustryNameStr(item) {
        if (item == null || item.industryList == null) {
            return "无";
        } else {
            const tempList = item.industryList.map((item) => item.industryName);
            return tempList.join(",");
        }
    },
    /**
     * 岗位
     * @param {*} item
     */
    formatterVueDutyNameStr(item) {
        if (item == null || item.dutyList == null) {
            return "无";
        } else {
            const tempList = item.dutyList.map((item) => item.dutyName);
            return tempList.join(",");
        }
    },
    /**
     *  处理字符串
     */
    formatterItemStr(str) {
        if (str == null || str == undefined || str.length == 0) {
            return "无";
        } else {
            return str;
        }
    },
    /**
     * 秒转分
     * @param {*} val
     */
    formatterMinutes(val) {
        let h = 0;
        let s = val;
        h = Math.floor(s / 60);
        //计算秒
        //算法：取得秒%60的余数，既得到秒数
        s = s % 60;
        //将变量转换为字符串
        h += "";
        s += "";
        //如果只有一位数，前面增加一个0
        //   h = h.length == 1 ? "0" + h : h;
        //   s = s.length == 1 ? "0" + s : s;
        let result = h;
        return result;
    },
    /**
     *  计算分钟秒数量
     */
    missToMinuteStr(s) {
        // 计算分钟
        // 算法：将秒数除以60，然后下舍入，既得到分钟数
        if (s == undefined || s == null) {
            return "0秒";
        }
        if (s == 0) {
            return "0秒";
        }
        let h;
        h = Math.floor(s / 60);
        // 计算秒
        // 算法：取得秒%60的余数，既得到秒数
        s %= 60;
        let showTime = "";
        if (h > 0) {
            showTime = `${h}分`;
            if (s == 0) {
                showTime = `${showTime}钟`;
            }
        }
        if (s > 0) {
            showTime = `${showTime + s}秒`;
        }
        if (showTime.length == 0) {
            showTime = "0秒";
        }
        return showTime;
    },
    /**
     * 方式
     */
    formatterSourceType(type) {
        if (!type || type == 0) {
            return "-";
        }
        // 签到来源（10：pc,20：Android，21：iOS，22：公众号，23小程序）
        let str = "";
        switch (type) {
            case 10: {
                str = "电脑(PC)";
                break;
            }
            case 14: {
                str = "现场考试";
                break;
            }
            case 15: {
                str = "现场学习";
                break;
            }
            case 20: {
                str = "安卓(APP)";
                break;
            }
            case 21: {
                str = "苹果(APP)";
                break;
            }
            case 22: {
                str = "公众号";
                break;
            }
            case 23: {
                str = "小程序";
                break;
            }
            case 30: {
                str = "电脑(H5)";
                break;
            }
            case 31: {
                str = "安卓(H5)";
                break;
            }
            case 32: {
                str = "苹果(H5)";
                break;
            }
            default: {
                str = "未知设备";
                break;
            }
        }
        return str;
    },
};
