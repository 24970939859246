import http from "../../util/http/studentHttp";
const baseUrl = process.env.VUE_APP_API_BASE_URL;
export default {
    /**
     * 获取基础的培训类别
     */
    getSubjectList(params) {
        return http.get(baseUrl + "/common/subject/list", params);
    },
};
